body .main__auth .auth-card {
  background-color: #f7faff;
  -webkit-box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
  box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
  border: 1px solid transparent;
}

body .main__auth .auth-card__other-way-label,
body .main__auth .auth-card__another,
body .main__auth .auth-radio__label,
body .main__auth .form-auth__label,
body .main__auth .form-auth__input,
body .main__auth .form-auth__paragraph {
  color: #454E53;
}

body .main__auth .auth-card__another a,
body .main__auth .auth-radio__label a {
  color: #2A59FF;
}

body .main__auth .form-auth__input {
  border: 1px solid #B5BACB;
}

body .main__auth .reset-link {
  cursor: pointer;
  color: #B5BACB;
}

body .main__auth .reset-link:hover {
  color: #2A59FF;
}

@media (prefers-color-scheme: dark) {
  body .main__auth .auth-card {
    background-color: rgba(41, 44, 45, 0.2);
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid rgba(69, 78, 83, 0.5019607843);
  }

  body .main__auth .auth-card__other-way-label,
  body .main__auth .auth-card__another,
  body .main__auth .auth-radio__label,
  body .main__auth .form-auth__label,
  body .main__auth .form-auth__input,
  body .main__auth .form-auth__paragraph {
    color: #fff;
  }

  body .main__auth .auth-card__another a,
  body .main__auth .auth-radio__label a {
    color: #2A59FF;
  }

  body .main__auth .form-auth__input {
    border: 1px solid rgba(69, 78, 83, 0.5019607843);
  }

  body .main__auth .reset-link {
    cursor: pointer;
    color: #DFE3F0;
  }

  body .main__auth .reset-link:hover {
    color: #2A59FF;
  }
}

html.dark body .main__auth .auth-card {
  background-color: rgba(41, 44, 45, 0.2);
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid rgba(69, 78, 83, 0.5019607843);
}

html.dark body .main__auth .auth-card__other-way-label,
html.dark body .main__auth .auth-card__another,
html.dark body .main__auth .auth-radio__label,
html.dark body .main__auth .form-auth__label,
html.dark body .main__auth .form-auth__input,
html.dark body .main__auth .form-auth__paragraph {
  color: #fff;
}

html.dark body .main__auth .auth-card__another a,
html.dark body .main__auth .auth-radio__label a {
  color: #2A59FF;
}

html.dark body .main__auth .form-auth__input {
  border: 1px solid rgba(69, 78, 83, 0.5019607843);
}

html.dark body .main__auth .reset-link {
  cursor: pointer;
  color: #DFE3F0;
}

html.dark body .main__auth .reset-link:hover {
  color: #2A59FF;
}

html.light body .main__auth .auth-card {
  background-color: #f7faff;
  -webkit-box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
  box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
  border: 1px solid transparent;
}

html.light body .main__auth .auth-card__other-way-label,
html.light body .main__auth .auth-card__another,
html.light body .main__auth .auth-radio__label,
html.light body .main__auth .form-auth__label,
html.light body .main__auth .form-auth__input,
html.light body .main__auth .form-auth__paragraph {
  color: #454E53;
}

html.light body .main__auth .auth-card__another a,
html.light body .main__auth .auth-radio__label a {
  color: #2A59FF;
}

html.light body .main__auth .form-auth__input {
  border: 1px solid #B5BACB;
}

html.light body .main__auth .form-auth__input {
  border: 1px solid #B5BACB;
}

html.light body .main__auth .reset-link {
  cursor: pointer;
  color: #B5BACB;
}

html.light body .main__auth .reset-link:hover {
  color: #2A59FF;
}

.main__auth {
  margin: 20px 0;
}

.auth__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.auth_reset .auth-card__title {
  margin-bottom: 8px;
}

.auth-card {
  padding: 26px 32px;
  border-radius: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: background-color ease-in-out 0.3s, border ease-in-out 0.3s, -webkit-box-shadow ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s, border ease-in-out 0.3s, -webkit-box-shadow ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s, border ease-in-out 0.3s, box-shadow ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s, border ease-in-out 0.3s, box-shadow ease-in-out 0.3s, -webkit-box-shadow ease-in-out 0.3s;
  max-width: 500px;
  width: 100%;
}

@media (max-width: 1200px) {
  .auth-card {
    padding-left: calc(20px + 12 * (100vw - 320px) / 880);
    padding-right: calc(20px + 12 * (100vw - 320px) / 880);
    padding-top: calc(16px + 10 * (100vw - 320px) / 880);
    padding-bottom: calc(16px + 10 * (100vw - 320px) / 880);
  }
}

.auth-card__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  gap: 16px;
}

.auth-card__desc {
  font-size: .85rem;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  margin-bottom: 1rem;
  opacity: .75;
}

.auth-card__title {
  font-family: Rubik;
  font-weight: 500;
  font-size: 26px;
  line-height: 138%;
}

@media (max-width: 1200px) {
  .auth-card__title {
    font-size: calc(20px + 6 * (100vw - 320px) / 880);
  }
}

.auth-card__ways {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.auth-card__other-way-label {
  text-transform: uppercase;
  font-size: 16px;
  font-family: Rubik;
  font-weight: 500;
  margin: 10px 0 16px 0;
  -webkit-transition: color ease-in-out 0.3s;
  transition: color ease-in-out 0.3s;
}

.auth-card__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.auth-card__another {
  font-size: 14px;
  line-height: 130%;
  -webkit-transition: color ease-in-out 0.3s;
  transition: color ease-in-out 0.3s;
}

.auth-card__another a {
  text-decoration: underline;
}

.auth-card__another a:hover {
  text-decoration: none;
}

.auth-card .reset-link {
  display: inline-block;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  -webkit-transition: color ease-in-out 0.3s;
  transition: color ease-in-out 0.3s;
}

.header-ways__way {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 12px;
  height: 30px;
  border-radius: 6px;
  color: #fff;
  -webkit-transition: background-color ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s;
}

.header-ways__way_fb {
  background-color: #3b5998;
}

.header-ways__way_fb:hover {
  background-color: #2c4171;
}

.header-ways__way_in {
  background-color: #0e76a8;
}

.header-ways__way_in:hover {
  background-color: #0a5a80;
}

.form-auth__part {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}
.form-auth__part.captcha {
  align-items: center;
  justify-content: center;
}

.form-auth__label {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  -webkit-transition: color ease-in-out 0.3s;
  transition: color ease-in-out 0.3s;
}

.form-auth__input {
  border-radius: 6px;
  background-color: transparent;
  height: 35px;
  padding: 0 8px;
  -webkit-transition: color ease-in-out 0.3s, border ease-in-out 0.3s;
  transition: color ease-in-out 0.3s, border ease-in-out 0.3s;
  width: 100%;
}

.form-auth__button {
  margin: 0 auto;
  margin: 0 auto;
  margin-top: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  height: 40px;
  border-radius: 6px;
  background-color: #2A59FF;
  padding: 0 16px;
  color: #fff;
  -webkit-transition: background-color ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s;
  font-size: 14px;
}

.form-auth__button:hover {
  background-color: #2249d5;
}

.form-auth__paragraph {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 300;
  line-height: 138%;
}

.auth-radio {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 8px;
}

.auth-radio__label {
  font-size: 14px;
  line-height: 130%;
  -webkit-transition: color ease-in-out 0.3s;
  transition: color ease-in-out 0.3s;
}

.auth-radio__label a {
  text-decoration: underline;
}

.auth-radio__label a:hover {
  text-decoration: none;
}

.auth-radio__custom {
  position: relative;
  top: 1px;
  width: 18px;
  height: 18px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border: 1px solid #c8ccd4;
  border-radius: 3px;
  vertical-align: middle;
  -webkit-transition: background-color 0.1s ease;
  transition: background-color 0.1s ease;
  cursor: pointer;
  display: block;
}

.auth-radio__custom::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 6px;
  width: 5.4px;
  height: 10px;
  opacity: 0;
  -webkit-transform: rotate(45deg) scale(0);
  transform: rotate(45deg) scale(0);
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.auth-radio__custom {
  margin-left: 5px;
  vertical-align: middle;
  cursor: pointer;
}

.policy:checked~.auth-radio__custom {
  border-color: transparent;
  background: #2A59FF;
}

.policy:checked~.auth-radio__custom::after {
  opacity: 1;
  -webkit-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
}

.auth-radio {
  position: relative;
}

.auth-radio__radio {
  display: none !important;
}
.errors-area {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 500;
  padding: .75rem;
  color: tomato;
  background: #fff0ed;
  border-radius: .25rem;
  text-align: center;
}
.dark .errors-area {
  color: #FFCDD2;
  background: #721515;
}

.errors-area .h6 {
  margin-bottom: .5rem;
}

.errors-area p {
  font-size: .75rem;
  line-height: 1.25;
  font-weight: 400;
  text-align: center;
}

.errors-area p:not(:last-child) {
  margin-bottom: 1rem;
}
.passcode-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
}
.passcode-form .header {
    font-size: 1rem;
    text-align: center;
    line-height: 1;
    font-weight: 500;
    margin-bottom: .5rem;
    width: 100%;
}
.passcode-form .m-field .number {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1;
    padding: .25rem;
    width: 2.5rem;
    height: 3rem;
    text-align: center;
}
.passcode-form .m-field:not(:last-child) {
    margin-right: .5rem;
}

re-captcha div:first-child {
  margin: 0 auto;
}
@media(max-width: 767.68px) {
  re-captcha {
    transform: scale(0.8);
    transform-origin: 0 0;
  }
}

